import { createStore } from "vuex";

export default createStore({
  state: {
    streets: [],
    districts: [],
    citys: [],
    residences: [],
    objs: [
      {
        id: "1",
        title: "Клубный дом Космо 4/22",
        address: "Москва, Космодамианская набережная, 4/22с8",
        lat: "55.7456721",
        lon: "37.6387192",
        mainPhoto: {
          id: "101",
          url: "https://spa-bitrix.ru/liss/parser/images/jk/img_1_1.jpg",
          width: null,
          height: null,
        },
        photos: [
          {
            id: "102",
            url: "https://spa-bitrix.ru/liss/parser/images/jk/img_1_2.jpg",
            width: null,
            height: null,
          },
          {
            id: "103",
            url: "https://spa-bitrix.ru/liss/parser/images/jk/img_1_3.jpg",
            width: null,
            height: null,
          },
          {
            id: "104",
            url: "https://spa-bitrix.ru/liss/parser/images/jk/img_1_4.jpg",
            width: null,
            height: null,
          },
          {
            id: "105",
            url: "https://spa-bitrix.ru/liss/parser/images/jk/img_1_5.jpg",
            width: null,
            height: null,
          },
          {
            id: "106",
            url: "https://spa-bitrix.ru/liss/parser/images/jk/img_1_6.jpg",
            width: null,
            height: null,
          },
          {
            id: "107",
            url: "https://spa-bitrix.ru/liss/parser/images/jk/img_1_7.jpg",
            width: null,
            height: null,
          },
          {
            id: "108",
            url: "https://spa-bitrix.ru/liss/parser/images/jk/img_1_8.jpg",
            width: null,
            height: null,
          },
          {
            id: "109",
            url: "https://spa-bitrix.ru/liss/parser/images/jk/img_1_9.jpg",
            width: null,
            height: null,
          },
          {
            id: "110",
            url: "https://spa-bitrix.ru/liss/parser/images/jk/img_1_0.jpg",
            width: null,
            height: null,
          },
        ],
        items: [
          /* {
            type: "Продажа",
            id: "16",
            lot_name: "Продается 1-комн. апартаменты, 47,07 м²",
            lot_adress: "Москва, Космодамианская наб.",
            lat: null,
            lon: null,
            lot_price: "75594420",
            lot_price_m2: "1606000",
            currency: "₽",
            rooms: "1",
            residense: "Клубный дом Космо 4/22",
            residenceID: "1",
            street: null,
            lot_square: "47.07",
            lot_bedroom: "1",
            status: "Новостройка",
            lot_floor: "2:6\n",
            lot_height: 3,
            wc: null,
            lot_otc: "",
            lot_description:
              "Объявление снято с публикации, поищите ещё что-нибудь",
            assigned: {
              name: "Фамилия Имя Отчество",
              position: "Должность",
              avatar: null,
              phone: "",
              email: "",
            },
            commision: null,
            lot_date_update: "2024-08-22 13:57:15",
            photos: [
              {
                id: "242",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_2.jpg",
                width: null,
                height: null,
              },
              {
                id: "243",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_3.jpg",
                width: null,
                height: null,
              },
              {
                id: "244",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_4.jpg",
                width: null,
                height: null,
              },
              {
                id: "245",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_5.jpg",
                width: null,
                height: null,
              },
              {
                id: "246",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_6.jpg",
                width: null,
                height: null,
              },
              {
                id: "247",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_7.jpg",
                width: null,
                height: null,
              },
              {
                id: "248",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_8.jpg",
                width: null,
                height: null,
              },
              {
                id: "249",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_9.jpg",
                width: null,
                height: null,
              },
              {
                id: "250",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_10.jpg",
                width: null,
                height: null,
              },
              {
                id: "251",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_11.jpg",
                width: null,
                height: null,
              },
              {
                id: "252",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_12.jpg",
                width: null,
                height: null,
              },
              {
                id: "253",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_13.jpg",
                width: null,
                height: null,
              },
              {
                id: "254",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_14.jpg",
                width: null,
                height: null,
              },
              {
                id: "255",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_15.jpg",
                width: null,
                height: null,
              },
              {
                id: "256",
                url: "https://spa-bitrix.ru/liss/parser/images/lot/img_16_16.jpg",
                width: null,
                height: null,
              },
            ],
          },*/
        ],
      },
    ],
    metro: [],
    filterOptions: {
      priceType: true,
      isHouse: false,
      isPlot: false,
      isApart: false,
      isSecondary: false,
      isNewBuild: false,
      isTerrace: false,
      isTownHouse: false,
      isWhitebox: false,
      isNotOtc: false,
      isOtc: false,
      rooms: {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
        six: false,
      },
      price: "",
      priceTo: "",
      priceFrom: "",
      square: "",
      statusObj: [],
      selectStatus: "",
      currency: "₽",
      city: [],
      district: [],
      streets: [],
      metro: [],
    },
    selectItems: [],
    isFilter: false,
  },
  getters: {},
  mutations: {
    addSelectItems(state, obj) {
      state.selectItems.push(obj);
    },
    newSelectItems(state, array) {
      state.selectItems = array;
    },
    deleteSelectItems(state, obj) {
      const index = state.selectItems.findIndex((e) => e.id == obj.id);
      state.selectItems.splice(index, 1);
    },
    isFilterUpdate(state, bool) {
      state.isFilter = bool;
    },
    streetsAdd(state, array) {
      state.streets = array;
    },
    districtsAdd(state, array) {
      state.districts = array;
    },
    citysAdd(state, array) {
      state.citys = array;
    },
    residencesAdd(state, array) {
      state.residences = array;
    },
    objsAdd(state, array) {
      state.objs = array;
    },
    itemsAdd(state, array) {
      const index = state.objs.findIndex((e) => e.id == array[0]);
      state.objs[index].items = array[1];
    },
    metroAdd(state, array) {
      state.metro = array;
    },
    filterOptionUpdate(state, obj) {
      state.filterOptions = obj;
    },
  },
  actions: {},
  modules: {},
});
