<template>
  <v-card
    class="pb-12"
    v-touch="{
      right: () => swipe(),
    }"
  >
    <div class="container">
      <textarea
        :value="urlCopy"
        id="custom_code"
        placeholder="here is embed code"
        readonly="readonly"
        class="displaynone"
      ></textarea>

      <v-alert
        v-model="isUrlCopy"
        closable
        variant="tonal"
        :text="`Ссылка на подборку скопирована`"
        color="#ff8351"
        style="margin-bottom: 20px"
      ></v-alert>
      <div class="header flex mb-3">
        <div class="header__title">
          <v-row class="justify-space-between">
            <v-col cols="1">
              <button
                class="btn-reset close__filter align-self-center"
                @click="backSwitch"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17 10C17 10.1989 16.921 10.3897 16.7803 10.5303C16.6397 10.671 16.4489 10.75 16.25 10.75L5.61201 10.75L9.77001 14.71C9.9064 14.8494 9.98312 15.0365 9.9839 15.2315C9.98467 15.4266 9.90943 15.6142 9.77414 15.7547C9.63885 15.8952 9.45416 15.9775 9.25923 15.9841C9.0643 15.9907 8.87447 15.921 8.73001 15.79L3.23001 10.54C3.15742 10.47 3.09968 10.3862 3.06025 10.2934C3.02081 10.2006 3.00049 10.1008 3.00049 10C3.00049 9.89919 3.02081 9.79941 3.06025 9.70663C3.09968 9.61385 3.15742 9.52997 3.23001 9.46001L8.73001 4.21001C8.8002 4.13826 8.88408 4.08133 8.97668 4.04259C9.06928 4.00386 9.16871 3.9841 9.26908 3.9845C9.36945 3.9849 9.46873 4.00544 9.56101 4.04491C9.6533 4.08437 9.73673 4.14196 9.80635 4.21426C9.87598 4.28657 9.93038 4.37211 9.96634 4.46582C10.0023 4.55953 10.0191 4.65951 10.0157 4.75982C10.0123 4.86014 9.98881 4.95875 9.9466 5.04983C9.9044 5.1409 9.84435 5.22257 9.77001 5.29001L5.61201 9.25001L16.25 9.25001C16.4489 9.25001 16.6397 9.32902 16.7803 9.46968C16.921 9.61033 17 9.80109 17 10Z"
                    fill="black"
                  />
                </svg>
              </button>
            </v-col>
            <v-col cols="8">
              <div class="title">Лотов {{ item.developer_lot }}</div>
            </v-col>
          </v-row>
        </div>
        <div class="search__block flex">
          <div class="search flex">
            <v-text-field
              clearable
              class="mr-2"
              v-model="search"
              label="Поиск"
              variant="outlined"
              hide-details
              @input="searchItems"
            ></v-text-field>
            <v-btn
              height="56px"
              class="text-none"
              style="border-radius: 12px"
              color="#FF8351"
              prepend-icon="mdi-format-list-text"
              variant="flat"
              @click="сhangeList()"
            >
              {{ typeItems }}
            </v-btn>
          </div>
        </div>
      </div>
      <div class="sort__block mb-2">
        <p>
          Сортировка: <b @click="sortMenu = !sortMenu">{{ sortType.name }}</b>
        </p>
      </div>
      <div class="map"></div>
      <div class="items__block__list flex">
        <div class="item" v-for="item in items" :key="item.id">
          <v-dialog
            v-model="item.dialog"
            transition="dialog-right-transition"
            fullscreen
          >
            <template v-slot:activator="{ props: activatorProps }">
              <CardItem
                v-if="!isList"
                :item="item"
                v-model="item.selected"
                v-bind="activatorProps"
              ></CardItem>
              <CardListItem
                v-else
                :item="item"
                v-model:="item.selected"
                v-bind="activatorProps"
              ></CardListItem>
            </template>
            <CardWiev
              :item="item"
              v-model:modal="item.dialog"
              v-model:select="item.selected"
            ></CardWiev>
          </v-dialog>
        </div>
      </div>
      <v-bottom-sheet v-model="options">
        <v-list class="rounded-t-xl" height="250">
          <v-list-subheader>Действия</v-list-subheader>
          <v-list-item
            :title="`Создать PDF`"
            :disabled="loading"
            :loading="loading"
            @click="presentationAdd('enable')"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-view-list-outline"></v-icon>
            </template>
          </v-list-item>
          <v-list-item
            title="Cбросить подборку"
            :disabled="loading"
            :loading="loading"
            @click="deleteSelectItems"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-trash-can-outline"></v-icon>
            </template>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
      <v-bottom-sheet v-model="sortMenu">
        <v-list class="rounded-t-xl" height="320">
          <v-list-subheader>Действия</v-list-subheader>
          <v-list-item
            v-for="sortItem in sortTypes"
            :key="sortItem.id"
            :title="sortItem.name"
            @click="sortItems(sortItem)"
          >
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
      <v-snackbar v-model="isPress" color="white" min-width="104%">
        <v-btn
          class="presentation__btn text-none mr-5"
          height="100%"
          width="75%"
          style="border-radius: 35px; padding: 25px; font-size: 16px"
          color="#FF8351"
          variant="flat"
          :disabled="loading"
          :loading="loading"
          @click="addSelection"
          >{{
            `Создать подборку (${this.$store.state.selectItems.length})`
          }}</v-btn
        >
        <v-btn
          class="rounded-circle mx-auto"
          style="height: 64px"
          @click="options = !options"
        >
          <svg
            width="16"
            height="4"
            viewBox="0 0 16 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.96484 3.61816C1.09473 3.61816 0.417969 2.94141 0.417969 2.07129C0.417969 1.20117 1.09473 0.524414 1.96484 0.524414C2.82422 0.524414 3.50098 1.20117 3.50098 2.07129C3.50098 2.94141 2.82422 3.61816 1.96484 3.61816ZM7.89453 3.61816C7.02441 3.61816 6.34766 2.94141 6.34766 2.07129C6.34766 1.20117 7.02441 0.524414 7.89453 0.524414C8.75391 0.524414 9.43066 1.20117 9.43066 2.07129C9.43066 2.94141 8.75391 3.61816 7.89453 3.61816ZM13.8242 3.61816C12.9541 3.61816 12.2773 2.94141 12.2773 2.07129C12.2773 1.20117 12.9541 0.524414 13.8242 0.524414C14.6836 0.524414 15.3604 1.20117 15.3604 2.07129C15.3604 2.94141 14.6836 3.61816 13.8242 3.61816Z"
              fill="#474849"
            />
          </svg>
        </v-btn>
      </v-snackbar>
    </div>
  </v-card>
</template>

<script>
import CardItem from "../home/CardItem.vue";
import CardListItem from "../home/CardListItem.vue";
import CardWiev from "../home/CardWiev.vue";
import axios from "axios";
import copy from "copy-to-clipboard";

export default {
  name: "ItemPage",
  components: {
    CardItem,
    CardListItem,
    CardWiev,
  },
  props: {
    item: Object,
    modelValue: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      items: [],
      loading: false,
      isUrlCopy: false,
      urlCopy: "",
      valueNav: "",
      search: "",
      typeItems: "Карточки",
      sortType: { id: 3, name: "Cначала новые" },
      sortMenu: false,
      filterMenu: false,
      options: false,
      isMap: false,
      isList: true,
      sortTypes: [
        { id: 1, name: "Cначала новые" },
        { id: 2, name: "Cначала старые" },
        { id: 3, name: "Cначала дешевые" },
        { id: 4, name: "Cначала дорогие" },
      ],
      filterItems: [],
    };
  },
  methods: {
    async getItems() {
      try {
        const items = [];
        const itemsData = await axios.get(
          "https://lissearch.ru/backend/api/api.php",
          { params: { params: "getLot", jk_id: this.item.id } }
        );
        for (const objItem of itemsData.data) {
          objItem.lot_name = objItem.lot_name
            .split("Продается")[1]
            .split(",")[0];
          objItem.dialog = false;
          objItem.selected = false;
          objItem.cian = false;
          objItem.coordinates = [objItem.lon, objItem.lat];
          //--------------------------
          objItem.fullPricexls = Number(objItem.lot_price);
          objItem.fullPrice = new Intl.NumberFormat("ru-RU").format(
            Number(objItem.lot_price)
          );
          objItem.squarePriceXls = Number(objItem.lot_price_m2);
          objItem.squarePrice = new Intl.NumberFormat("ru-RU").format(
            Number(objItem.squarePriceXls)
          );
          objItem.comission = objItem.comission ? objItem.comission : "3%";
          objItem.assigned = {
            name: "Фамилия Имя Отчество",
            position: "Должность",
            avatar:
              "https://img.freepik.com/free-photo/front-view-of-smiley-man-outdoors-in-the-city_23-2148955558.jpg",
            phone: "",
            email: "",
          };
          objItem.wc = objItem.wc ? objItem.wc : "0";
          objItem.lot_bedroom = objItem.lot_bedroom ? objItem.lot_bedroom : "0";
          objItem.currency = objItem.currency ? objItem.currency : "₽";
          objItem.status = "Новостройка";
          objItem.type = "Продажа";
          objItem.photos = objItem.lot_photo_url.split(";");
          items.push(objItem);
        }
        this.items = items;
        this.filterItems = this.items;
        this.$store.commit("itemsAdd", [this.item.id, this.items]);
      } catch (e) {
        console.log(e);
      }
    },
    backSwitch() {
      this.$emit("update:modelValue", false);
    },
    swipe() {
      this.$emit("update:modelValue", false);
    },
    searchItems() {
      if (this.search.length > 0) {
        this.filterItems = this.$store.state.objs.filter((item) => {
          return (
            //название, адрес, сумма,
            item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
            item.priceTo.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1 ||
            item.priceFrom.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1 ||
            item.address.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        this.filterItems = this.filterItems = this.$store.state.objs;
      }
    },
    sortItems(item) {
      try {
        this.sortType = item;
        switch (item.id) {
          case 1:
            console.log(item.id, "1");
            this.filterItems.sort((a, b) => {
              return Number(a.id) - Number(b.id);
            });
            break;
          case 2:
            console.log(item.id, "2");
            this.filterItems.sort((a, b) => {
              return Number(b.id) - Number(a.id);
            });
            break;
          case 3:
            console.log(item.id, "3");
            this.filterItems.sort((a, b) => {
              return a.fullPricexls - b.fullPricexls;
            });
            break;
          case 4:
            console.log(item.id, "4");
            this.filterItems.sort((a, b) => {
              return b.fullPricexls - a.fullPricexls;
            });
            break;
        }
        this.sortMenu = false;
      } catch (error) {
        console.log({ error });
      }
    },
    сhangeList() {
      this.isList = !this.isList;
      if (this.isList) this.typeItems = "Карточки";
      else this.typeItems = "Список";
    },
    deleteSelectItems() {
      try {
        for (const item of this.$store.state.selectItems) {
          item.selected = false;
        }
        this.$store.commit(
          "newSelectItems",
          this.$store.state.selectItems.filter((e) => e.selected)
        );
        this.options = false;
      } catch (error) {
        console.log({ error });
      }
    },
    async presentationAdd(type) {
      try {
        console.log("presentationAdd");
        this.loading = true;
        let formData = new FormData();
        for (let i = 0; i < this.$store.state.selectItems.length; i++) {
          const item = this.$store.state.selectItems[i];
          item.currencyArr = ["RUB", item.currency];
          for (const index in item) {
            formData.append(`cards[${i}][${index}]`, item[index]);
          }
          formData.append(`cards[${i}][presType]`, type);
        }

        const res = await axios.post(
          "https://lissearch.ru/backend/presentation/create_presentation.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        //  console.log({ res });
        let name = `Презентация ${this.items.length} объектов.pdf`;
        if (this.items.length < 2) {
          name = `${this.items[0].name}-${this.items[0].square} m2.pdf`;
        }
        const link = document.createElement("a");
        link.href = res.data;
        link.download = name;
        this.loading = false;
        link.click();
      } catch (error) {
        console.log({ error });
      }
    },
    pause(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async addSelection() {
      let url = "https://client.lissearch.ru/?";
      const objs = this.$store.state.selectItems;
      console.log({ objs });
      for (const indexObj in objs) {
        if (indexObj == 0) {
          url += `id=${objs[indexObj].id}`;
        } else {
          url += `&id=${objs[indexObj].id}`;
        }
      }
      const clickru = await axios.get("https://clck.ru/--", {
        params: { url },
      });
      const saveUrl = clickru.data;
      this.urlCopy = saveUrl;
      //const isUrlCopy = await navigator.clipboard.writeText(
      // "text to clipboard"
      //);
      //console.log({ isUrlCopy });
      let custom_code = document.querySelector("#custom_code");
      custom_code.classList.remove("displaynone");
      await this.pause(1);
      custom_code.select();
      document.execCommand("copy");
      custom_code.classList.add("displaynone");
      console.log(custom_code);
      this.isUrlCopy = true;
      console.log({ saveUrl });
      copy(this.urlCopy);
      this.options = false;
    },
    getOptions() {
      try {
        this.options = true;
      } catch (error) {
        console.log({ error });
      }
    },
  },
  computed: {
    isPress() {
      return this.$store.state.selectItems.length > 0;
    },
  },
  async mounted() {
    console.log(this.item, "item");
    if (this.item.items.length > 0) {
      this.items = this.item.items;
      this.filterItems = this.items;
    } else {
      await this.getItems();
    }
  },
};
</script>
<style scoped>
.search__block {
  justify-content: space-between;
}
.search {
  margin-right: 0;
}
.v-list-item__prepend {
  display: block;
}
</style>
